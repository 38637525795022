import { defineConfig } from './config.default'

export default defineConfig({
	signature: {
		validate_pages: false
	},
	
	summary: {
		validate_pages: true
	},
	
	backend: {
		override: 'default'
	},
	
	maintenance: {
		message: undefined
	}
})